import ApiService from "@/core/services/api.service";
import querystring from "querystring";
import _ from "lodash";

export const BASE_URL = "/api/instruction-type";
export const MODULE_NAME = "instructionTypes";
export const LOADING = `LOADING`;
export const ERROR = `ERROR`;
export const SUCCESS = `SUCCESS`;
export const ITEMS = `ITEM`;
export const ITEMS_ALL = `ITEMS_ALL`;
export const ITEM = `CURRENT_ITEM`;

export const SET_LOADING = `M/LOADING`;
export const SET_ERROR = `M/ERROR`;
export const SET_SUCCESS = `M/SUCCESS`;
export const SET_ITEMS = `M/ITEMS`;
export const SET_ITEM = `M/CURRENT_ITEM`;
export const RESET_VALUES = `M/RESET_VALUES`;
export const SET_ITEMS_ALL = `M/ITEMS_ALL`;

// ACTIONS
export const GET_ITEMS_ALL = `GET_ITEMS_ALL`;
export const GET_ITEMS = `GET_ITEMS`;
export const GET_ITEM = `GET_ITEM`;
export const CREATE_ITEM = `CREATE_ITEM`;
export const UPDATE_ITEM = `UPDATE_ITEM`;
export const DELETE_ITEM = `DELETE_ITEM`;
export const HANDLE_INFINITE_SCROLL = 'HANDLE/INFINITE_SCROLL';
export const CHANGE_STATUS_BY_ID = 'CHANGE_STATUS_BY_ID';

const state = {
    items: null,
    items_all: null,
    item: {},
    loading: false,
    error: null,
    success: null,
};

const getters = {
    [LOADING]: (state) => {
        return state.loading;
    },
    [ERROR]: (state) => {
        return state.error;
    },
    [SUCCESS]: (state) => {
        return state.success;
    },
    [ITEMS]: (state) => {
        return state.items;
    },
    [ITEMS_ALL]: (state) => {
        return state.items_all;
    },
    [ITEM]: (state) => {
        return state.item;
    },
};

const mutations = {
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
    [SET_ERROR]: (state, payload) => {
        state.error = payload;
    },
    [SET_ITEMS]: (state, payload) => {
        state.items = payload;
    },
    [SET_ITEMS_ALL]: (state, payload) => {
        state.items_all = payload;
    },
    [SET_SUCCESS]: (state, payload) => {
        state.success = payload;
    },
    [SET_ITEM]: (state, payload) => {
        state.item = payload;
    },
    [RESET_VALUES]: (state) => {
        state.success = null;
        state.error = null;
        state.loading = false;
        state.item = {};
        state.items = [];
    },
};

const actions = {
    /**
     * This function will allow to do behind the process of infinite scroll
     *
     *
     * Note: To use this method payload must include filters as filters.
     * Inside the filters, it must have page property to indicate current page
     * $state for infinite scroll (if $state wasn't passed or null it will reset the state)
     *
     * @param context
     * @param payload
     */
    [HANDLE_INFINITE_SCROLL]: (context, payload) => {
        if (!('filters' in payload) || !('page' in payload.filters)) {
            throw "To use the infinite feature, please pass filters with page in payload";
        }
        if (context.state.loading) {
            return new Promise(function (resolve) {
                resolve({
                    status: true,
                    data: payload
                });
            });
        }
        if (document.activeElement !== document.body) document.activeElement.blur();
        context.commit(SET_LOADING, true);

        if (payload.$state == null) {
            context.commit(SET_ITEMS, {});
            payload.filters.page = 1;
        }

        let items = {...context.state.items};

        if (Number(items.last_page ? items.last_page : Infinity) < payload.filters.page) {
            context.commit(SET_LOADING, false);
            return new Promise(function (resolve) {
                resolve({
                    status: false,
                    end: true,
                    error: "End of the scroll"
                });
            });
        }
        return ApiService.get(`${payload.url}?${querystring.stringify(payload.filters)}`).then(response => {
            if (!Object.keys(items).length) {
                items = {...response.data};
            } else {
                items.data = [...items.data, ...response.data.data];
            }
            delete response.data.data;
            items = {...items, ...response.data};
            if (Number(items.last_page) >= payload.filters.page) {
                payload.filters.page += 1;
            }
            context.commit(SET_ITEMS, items);
            context.commit(SET_LOADING, false);
            return {status: true, data: payload};
        }).catch(error => {
            context.commit(SET_LOADING, false);
            return {status: false, error};
        });
    },
    [GET_ITEMS]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let filters = payload.filters;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ITEMS, response.data);
            })
            .catch((error) => {
                context.commit(SET_ERROR, error);
            });
    },
    [GET_ITEMS_ALL]: (context, payload) => {
        let url = payload.url;
        let filters = payload.filters;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ITEMS_ALL, response.data);
            })
            .catch((error) => {
                context.commit(SET_ERROR, error);
            });
    },
    [GET_ITEM]: (context, payload) => {
        let url = payload.url || BASE_URL;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(url)
            .then((response) => {
                context.commit(SET_ITEM, response.data);
                context.commit(SET_LOADING, false);
            }).catch((error) => {
                context.commit(SET_ERROR, error);
            });
    },
    [UPDATE_ITEM]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let contents = payload.contents;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        ApiService.put(url, contents)
            .then((response) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ITEM, response.data);
            }).catch((error) => {
            context.commit(SET_ERROR, error);
        });
    },
    [CREATE_ITEM]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let contents = payload.contents;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        context.commit(SET_SUCCESS, false);
        ApiService.post(url, contents)
            .then((response) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ITEM, response.data);
                context.commit(SET_SUCCESS, true);
            }).catch((error) => {
            context.commit(SET_LOADING, false);
            context.commit(SET_ERROR, error);
        });
    },
    [CHANGE_STATUS_BY_ID]: (context, payload) => {
        let url = payload.url;
        let id = payload.id;
        context.commit(SET_LOADING, true);
        ApiService.put(url).then((res) => {
            let dataIndex = _.findIndex(context.state.items.data, row => row.id === id);

            if (dataIndex !== -1)
                context.state.items.data[dataIndex].status = !context.state.items.data[dataIndex].status;

            context.commit(SET_LOADING, false);
        }).catch((error) => {
            context.commit(SET_ERROR, error);
        });
    },
    [DELETE_ITEM]: (context, payload) => {
        let url = payload.url;
        let id = payload.id;

        context.commit(SET_LOADING, true);
        context.commit(SET_SUCCESS, false);
        ApiService.delete(url).then((_) => {
            if (context.state.items.data !== undefined) {
                for (let i = 0; i < context.state.items.data.length; i++) {
                    let currentItem = context.state.items.data[i];
                    if (Number(id) === Number(currentItem.id)) {
                        context.state.items.data.splice(i, 1);
                        break;
                    }
                }
            } else {
                for (let i = 0; i < context.state.items.length; i++) {
                    if (Number(id) === Number(context.state.items[i].id)) {
                        context.state.items.splice(i, 1);
                        break;
                    }
                }
            }
            context.commit(SET_SUCCESS, true);
            context.commit(SET_LOADING, false);
        }).catch((error) => {
            context.commit(SET_ERROR, error);
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
